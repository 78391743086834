#nprogress .bar {
    background: #ffeb00;
}


#nprogress .peg {
    box-shadow: 0 0 10px #ffca00, 0 0 5px #ffdd00;
}

#nprogress .spinner {
    display: none;
}
